<template>
  <div class="container container-learn-about-gideons">
    <div class="gideon_wrapper">
      <div class="gideon_img">
        <div class="row">
          <div class="col md-order-1">
            <p>{{ cms.page.fgThroughTheLordsGuidance }}</p>
            <p class="girdeon_paragraph">
              {{ cms.page.fgWorldWideMembership }}
            </p>
          </div>

          <div class="col md-order-2">
            <img src="../assets/images/gideon/Testaments_4.png"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container_purpose">
    <div class="gideon_purpose">
      <div class="left_gideon_wrapper">
        <img src="../assets/images/gideon/Prayer_4.png"/>
      </div>

      <div class="right_gideon_wrapper">
        <p>
          {{ cms.page.fgTheEnduringPurpose }}
        </p>

        <ul>
          <li>
            {{ cms.page.fgTheAssociationOfChristians }}
          </li>
          <li>{{ cms.page.fgPersonalTestimony }}</li>
          <li>{{ cms.page.fgPlacingAndDistributing }}</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container_design">
    <div class="row gideon_design">
      <div class="left_gideon_design_wrapper col md-order-1">
        <p>
          {{ cms.page.fgByDesign }}
        </p>
        <p class="girdeon_paragraph">
          {{ cms.page.fgTheGideonInternational }}
        </p>
      </div>

      <div class="right_gideon_design_wrapper col md-order-2">
        <img src="../assets/images/gideon/Distribution_1.png"/>
      </div>
    </div>
  </div>
  <div class="container_purpose">
    <div class="gideon_purpose">
      <div class="gideon_card">
        <div class="card_gideon_container">
          <h4>{{ cms.page.fgLearnMoreAbout }}</h4>
        </div>

        <div class="card_gideon_container">
          <a href="https://invite.gideons.org/" target="_blank">
            <button class="btn btn-default no-extra-l-margin">{{ cms.page.fgBecomeAGideon }}</button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapStores} from "pinia";
import {useDesignStore} from "@/stores/designStore";
import {useCmsStore} from "@/stores/cmsStore";

export default {
  name: "LearnAboutGideons",
  data() {
    return {
      cms: {
        loaded: false, // set to 'true' when CMS content has been returned from API
        options: {
          Common: true,
          Components: [],
          LanguageCode: "",
          PageName: "learn_more_about_the_gideons",
          MinistryUpdates: false,
        },
        page: {},
      },
    };
  },
  computed: {
    ...mapStores(useCmsStore, useDesignStore),
  },
  async created() {
    this.setContentLoaded(false);
    this.setTitlePageFromCms()
    await this.fetchCmsData();
  },
  methods: {
    async fetchCmsData() {
      try {
        await this.cmsStore
            .getPageAndOrCommonAndOrComponents(this.cms.options)
            .then((results) => {
              if (results) {
                this.cms.page = results;
              } else return false;
              this.setTitlePageFromCms(results)
              this.setContentLoaded(true);
              return true;
            });
      } catch (e) {
        console.error(e);
      }
    },
    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus;
    },
    setTitlePageFromCms(page) {
      const pageTitle = page && page.fgLearnAboutTheGideons ? page.fgLearnAboutTheGideons : 'Learn about The Gideons'
      const pageSubtitle = page && page.fgMoreAboutTheGideons ? page.fgMoreAboutTheGideons : 'More About The Gideons International'

      this.designStore.setTitlePage({
        title: pageTitle,
        sub_title: pageSubtitle,
        img_class: "hero-default-desktop",
        btn_history: false,
        width: 500,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  padding: 0 115px 120px;
  @media screen and (max-width: $xl) {
    padding: 0 33px 72px;
  }
}

div.container-learn-about-gideons {
  padding-top: 60px;
}

.gideon_wrapper {
  margin-top: -50px;
}

.gideon_img {
  color: #000;

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: $xl) {
      flex-direction: column;
    }
  }

  .col {
    font-size: 18px;
    line-height: 1.5;
    @media screen and (min-width: $xl) {
      width: 50%;
    }

    p {
      max-width: 95%;
      font-size: 18px;
      line-height: 1.5;
      padding: 50px 15px 0 0;
      @media screen and (max-width: $xl) {
        font-size: 15px;
        padding: 25px 0;
      }
      @media screen and (max-width: $xxl) {
        max-width: 100%;
        font-size: 17px;
        line-height: 1.8;
        padding: 24px 0px 22px 0;
      }
    }

    .girdeon_paragraph {
      padding: 1px 15px 0 0;
      @media screen and (max-width: $xxl) {
        padding: 0;
      }
      @media screen and (max-width: $xl) {
        padding: 20px 0px 0 0;
      }
    }
  }

  img {
    width: 75%;
    max-width: 812px;
    margin: -3% 0 0 285px;
    position: relative;
    z-index: 2;
    @media screen and (max-width: $xxl) {
      width: 100%;
      max-width: 814px;
      margin: -17% 0 0 115px;
    }
    @media screen and (max-width: $xl) {
      width: 100%;
      max-width: 814px;
      margin: -2% 0 0 6px;
    }
  }

  .md-order-1 {
    @media screen and (max-width: $xl) {
      order: 2;
    }
  }

  .md-order-2 {
    @media screen and (max-width: $xl) {
      order: 1;
    }
  }
}

.container_purpose {
  padding: 0 115px 5px;
  background: #fff;
  @media screen and (max-width: $xxl) {
    padding: 0 30px 5px;
  }
  @media screen and (max-width: $lg) {
    padding: 0 35px 5px;
  }
  @media screen and (max-width: $sm) {
    padding: 0;
  }
}

.gideon_purpose {
  display: flex;

  @media screen and (max-width: $lg) {
    flex-wrap: wrap;
  }

  .left_gideon_wrapper {
    width: 30%;
    margin-bottom: -2rem;
    @media screen and (max-width: $lg) {
      width: 100%;
    }
    @media screen and (max-width: $xl) {
      width: 100%;
    }
    @media screen and (max-width: $lg) {
      margin-bottom: 0;
    }

    img {
      max-width: 812px;
      margin: -5% 0 0;
      position: relative;
      z-index: 2;
      @media screen and (max-width: $lg) {
        opacity: 0.6;
      }
      @media screen and (max-width: $sm) {
        width: 100%;
      }
      @media screen and (max-width: $xl) {
        width: 85%;
      }
      @media screen and (max-width: $lg) {
        width: 100%;
      }
    }

    p {
      text-transform: uppercase;
      font-weight: 500;
      margin-top: 20px;
      @media screen and (max-width: $lg) {
        display: flex;
        justify-content: center;
      }
    }

    @media screen and (max-width: $lg) {
      width: 100%;
    }
  }

  .right_gideon_wrapper {
    width: 70%;
    padding-left: 28rem;
    padding-top: 8rem;

    @media screen and (max-width: $xxl) {
      padding-left: 26rem;
      padding-top: 5rem;
    }
    @media screen and (max-width: $xl) {
      padding-left: 0rem;
      padding-top: 1rem;
    }

    P {
      font-size: 18px;
      @media screen and (max-width: $xl) {
        font-size: 19px;
        line-height: 33px;
      }
      @media screen and (max-width: $sm) {
        font-size: 15px;
        line-height: 27px;
      }
    }

    ul {
      margin-left: 3rem;
      margin-top: 20px;
      line-height: 32px;
      @media screen and (max-width: $xl) {
        margin-left: 3rem;
        margin-top: 16px;
        line-height: 32px;
      }
      @media screen and (max-width: $sm) {
        margin-left: 1rem;
        margin-top: 7px;
        line-height: 24px;
      }
    }

    @media screen and (max-width: $lg) {
      width: 80%;
      margin-top: -365px;
      z-index: 2;
      height: 100%;
      padding: 29px 17px;
      margin-left: 15px;
      background: white;
    }
    @media screen and (max-width: $lg) {
      width: 100%;
      margin-top: 0;
      z-index: 2;
      font-size: 17px;
      height: 247px;
      padding: 3px 6px;
      margin-left: 10px;
    }
  }
}

.container_design {
  padding: 0 115px 5px;
  @media screen and (max-width: $xxl) {
    padding: 0 30px 5px;
  }
  @media screen and (max-width: $lg) {
    padding: 0 33px 72px;
  }

  .gideon_design {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $lg) {
      flex-wrap: wrap;
    }

    .left_gideon_design_wrapper {
      width: 50%;
      padding-top: 8rem;
      object-fit: cover;
      @media screen and (max-width: $lg) {
        width: 100%;
      }
      @media screen and (max-width: $sm) {
        padding-top: 4rem;
        width: 100%;
      }

      P {
        font-size: 18px;
        @media screen and (max-width: $sm) {
          font-size: 16px;
        }
      }

      .girdeon_paragraph {
        padding: 0 10px 40px 0;
      }
    }

    .right_gideon_design_wrapper {
      width: 40%;
      object-fit: cover;
      @media screen and (max-width: $lg) {
        width: 100%;
      }

      img {
        width: 85%;
        margin-left: 0px;
        margin-right: 90px;
        object-fit: cover;
        @media screen and (max-width: $xxl) {
          width: 78%;
          margin-left: 110px;
          margin-right: 0;
        }
        @media screen and (max-width: $lg) {
          width: 100%;
          margin-left: 0px;
        }
      }

    }

    .md-order-1 {
      @media screen and (max-width: $lg) {
        order: 2;
      }
    }

    .md-order-2 {
      @media screen and (max-width: $lg) {
        order: 1;
        margin-top: 7rem;
      }
    }
  }
}

.gideon_card {
  width: 50%;
  margin: 0 auto;
  background: #ffffff;
  padding: 60px 0px;
  position: relative;
  margin-top: 14px;

  h4 {
    font-weight: 500;
  }

  button {
    margin-left: 23rem;
    margin-top: 2rem;
    font-weight: 500;
    @media screen and (max-width: $lg) {
      margin-left: 13rem;
    }
    @media screen and (max-width: $sm) {
      margin-left: 3rem;
    }
  }

  @media screen and (max-width: $xxl) {
    width: 70%;
  }

  @media screen and (max-width: $xl) {
    width: 90%;
  }

  .card_gideon_container {
    padding: 2px 16px;
    display: flex;
    justify-content: center;
  }
}

.gideon_card button.no-extra-l-margin {
  margin-left: 0;
}
</style>